
import { ROOT_ACTIONS } from '@/store/actions';
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';

import { Program } from '@/types';
import { deleteDoc, doc } from 'firebase/firestore';
import { firestore } from '@/firebase';

@Component
export default class ConfirmationDialog extends Vue {
  @VModel({ required: true }) show!: boolean;
  @Prop({ required: true }) program!: Program;

  deleteUser = true;

  loading = false;

  async submit(){
    try {
      if(!this.program) return;
      this.loading = true;

      if(this.deleteUser) {
        await deleteDoc(doc(firestore, 'users', this.program.participant))
      }

      await deleteDoc(doc(firestore, 'programs', this.program.id))

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'info',
        text: 'Verwijderd'
      })

      this.$emit('deleted');
      this.show = false;

    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'error'
      })
    } finally {
      this.loading = false;
    }
  }
}
